<template>
  <banded-section
    title="Delete Relations"
    :collapsable="false"
  >
    <template slot="tooltip">
      <div class="m-b-m">
        This section is visible to the Hypefactors team only.
      </div>
      <div class="m-b-m">
        Here you can delete Relations.
      </div>
    </template>
    <h4 class="title is-4 has-text-black has-text-serif">
      Delete Relations
    </h4>
    <div class="Relations-import columns">
      <div class="column is-6 is-offset-3">
        <form enctype="multipart/form-data" @submit.prevent="submit">
          <form-field
            :validator="$v.form.organisation"
            label="Organisation id"
          >
            <organisations-picker
              v-model="form.organisation"
              popper-class="OrganisationsModal"
              placeholder="Choose organisation"
              @input="handleOrganisationSelect"
            />
          </form-field>
          <form-field :validator="$v.form.brands" label="Brands">
            <basic-select
              v-model="form.brands"
              :values="brandsToPickFrom"
              label-prop="name"
              value-prop="id"
              key-prop="id"
              multiple
              clearable
            />
          </form-field>

          <v-button
            :loading="form.isLoading"
            type="submit"
            class="is-primary is-pulled-right"
          >
            Delete
          </v-button>
        </form>
      </div>
    </div>
  </banded-section>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'
import OrganisationsPicker from '@/components/forms/OrganisationsPicker'

function createForm () {
  return new Form({
    organisation: { value: null },
    brands: { value: [], rules: { required } }
  })
}

export default {
  components: { OrganisationsPicker },
  data: () => ({
    form: createForm(),
    brands: [],
    results: []
  }),

  computed: {
    brandsToPickFrom () {
      return this.$safeGet(this.form.organisation, 'brands.data', [])
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      try {
        this.form.setPayloadTransformer((data) => {
          return {
            data: {
              brands: data.brands
            }
          }
        })

        const res = await this.form.submit('delete', '/staff/relations')

        this.form.reset()
        this.$v.form.$reset()
        this.results = res.data
      } catch (err) {
        this.$displayRequestError(err)
      }
    },
    handleOrganisationSelect (value) {
      this.form.brands = []
      this.form.organisation = value
    }
  }
}
</script>
